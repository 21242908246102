import Langs from "@/langs"
import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {getPageRoute} from "@platformaone/common/app/stores/shared.js"
import {useAppStore} from "./app.js"
import cloneDeep from "lodash/cloneDeep"

export const useSiteStore = defineStore("site", () => {
  // stores
  const appStore = useAppStore()

  // state
  const id = ref(null)
  const siteVar = ref({
    pages: [],
    ...window.site, // until it is overwritten by fetched JSON
  })
  const siteVarFetched = ref(false)

  // getters
  const sitePlatform = computed(() => siteVar.value.global.platform)
  const siteLangCurrent = computed(() => siteVar.value.lang.current)
  const siteLangDefault = computed(() => siteVar.value.lang.default)
  const siteLangsAvailable = computed(() => siteVar.value.lang.available)
  const pages = computed(() => siteVar.value.pages)
  const routes = computed(() => siteVar.value.routes)
  const routesReversed = computed(() => {
    const availableLangs = siteVar.value.lang.available
    let map = {}

    availableLangs.forEach((lang) => {
      siteVar.value.pages.forEach((p) => {
        if (!map[p.id]) map[p.id] = {}
        map[p.id][lang] = getPageRoute(p.id, siteVar.value.pages, lang)
      })
    })

    return map
  })
  const hasShop = computed(() => (siteVar.value.shopId ? true : false))

  // actions
  function updatePage(args) {
    const pageIndex = siteVar.value.pages.findIndex((p) => p.id == args.page.id)

    siteVar.value.pages[pageIndex] = args.page
  }

  function updateBlock(args) {
    const pageIndex = siteVar.value.pages.findIndex((p) => p.id == args.block.pageId)
    const blockIndex = siteVar.value.pages[pageIndex].blocks.findIndex((b) => b.id == args.block.id)

    siteVar.value.pages[pageIndex].blocks[blockIndex]._contentsData.content = cloneDeep(
      args.block.content
    ) // args.content
    siteVar.value.pages[pageIndex].blocks[blockIndex]._contentsData.stylesConfig = cloneDeep(
      args.block.stylesConfig
    ) // args.stylesConfig
    siteVar.value.pages[pageIndex].blocks[blockIndex].config = cloneDeep(args.block.config)
  }

  function setSiteVar(args) {
    siteVar.value = args.siteVar
    id.value = args.siteVar.id
    siteVarFetched.value = true

    // add lang overrides
    if (args.siteVar.overrides && args.siteVar.overrides.copy) {
      Langs.overrides = args.siteVar.overrides.copy
    }

    // set theme name. TODO: do this in DB
    const themeNames = {
      fhG0HNTsYSCUIXNbbGAy: "provoni",
      "7CJycfY8tjucn3oaN3t7": "novorocenky",
      mzg3gFiStZqa31ZqOfvj: "danielapolubedovova",
      "3yvJ7NyhhHFaY8xZrJpf": "eventy-salabka",
      x291zbwTyMOheOSYNKBj: "salabka", // development
      CLPbVM5yMxX2xrBAsoCL: "salabka", // production
      cDPbjTuuDcZzkgcMyoOd: "eventsalabka", // production
      zh9h3fpThoEfIbVQfPW8: "eventsalabka", // bezshopu on local_dev
      POlYQecQMZKuaBnre3ke: "zlesart",
      "1NcfygTm5o9X1CWZHhvF": "kouseklesa", // development
      BWoqqLVbjjFufL0OgOCS: "kouseklesa", // production
      gwwFwE9nONhDj95o0pOn: "jsemzena", // development
      GdqLPBJ2Kggup0DdIlWe: "jsemzena", // production
      SylbuOrBMFxJBCozuXXu: "sedlackova", // sedlackova2 development
      CwwZaM8PuyGI6YHEd5uS: "sedlackova", // sedlackova2 production
      QFCo1nQZYJ6ZjdYg4G86: "letterpresscafe", // production
      "8Cty2mwGM7y8QfBCQUzF": "tonesundas", // production
      tBxnhfaR5nbKeKY9VWW4: "zavadil", // production
      yRKjiYBGTCQtqdSgZ0TG: "bioplastica", // production
      nUQqMECliJSBNPQgCKzO: "burgerman", // production
    }
    if (themeNames[id.value]) {
      appStore.app.selectedThemeName = themeNames[id.value]
    }
  }

  function setCurrentLang(args) {
    siteVar.value.lang.current = args.lang
  }

  return {
    // state
    id,
    siteVar,
    siteVarFetched,

    // getters
    sitePlatform,
    siteLangCurrent,
    siteLangDefault,
    siteLangsAvailable,
    pages,
    routes,
    routesReversed,
    hasShop,

    // actions
    updatePage,
    updateBlock,
    setSiteVar,
    setCurrentLang,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteStore, import.meta.hot))
}
