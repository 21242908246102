import enumsTimezonesTiny from "../enums/timezonesTiny.js"

const optionsTimezonesTiny = enumsTimezonesTiny.map((timezone) => {
  return {
    value: timezone.name,
    label: timezone.name,
  }
})

export default optionsTimezonesTiny
