<template>
  <OneFooter
    :variant="variant"
    :override="{}"
    :logo="{
      src: logoSrc,
      imageId: logoImageId,
      width: logoWidth,
    }"
    :caption="caption"
    :website="website"
    :phone="phone"
    :email="email"
    :facebook="facebook"
    :instagram="instagram"
    :linkedin="linkedin"
    :twitter="twitter"
    :github="github"
    :dribbble="dribbble"
    :tripadvisor="tripadvisor"
  >
  </OneFooter>
</template>

<script>
import mixinBlock from "@/mixins/block"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "pfmFooter",
  mixins: [mixinBlock],
  props: ["block"],
  setup() {
    // stores
    const siteStore = useSiteStore()

    // states
    const {id: siteId} = storeToRefs(siteStore)
    const {siteLangCurrent} = storeToRefs(siteStore)

    return {
      siteId,
      siteLangCurrent,
    }
  },
  data: () => ({
    defaults: {
      elements: {
        logo: {
          styles: {
            width: {
              mobile: {
                value: 75,
                unit: "vw",
              },
              tablet: {
                value: 40,
                unit: "vw",
              },
              desktop: {
                value: 20,
                unit: "vw",
              },
            },
          },
        },
      },
    },
  }),
  computed: {
    variant() {
      return this.block.config.variant
    },
    logoWidth() {
      return this.styles.logo.width
    },
    logoImage() {
      if (!this.c) return
      if (!this.c.logo || !this.c.logo[this.siteLangCurrent]) return false
      return this.c.logo[this.siteLangCurrent]
    },
    logoImageId() {
      if (!this.c) return
      return this.logoImage.imageId
    },
    logoSrc() {
      if (!this.c || !this.logoImage) return
      let contentType = this.logoImage.contentType
      let params = ""
      if (contentType == "image/svg+xml") params = ""
      if (contentType == "image/jpeg") params = "$w_320,q_80,x_3"
      if (contentType == "image/png") params = "$w_320,c_9,x_3"
      let logoUrl = `${import.meta.env.VITE_URL_STATIC}/sites/${this.siteId}/images/${
        this.logoImageId
      }${params}`
      return logoUrl
    },
    caption() {
      if (!this.c) return
      let v = this.c.caption
      if (!v || !v[this.siteLangCurrent]) return false
      return v[this.siteLangCurrent]
    },
    website() {
      if (!this.c) return
      let v = this.c.website
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: v,
        label: v,
      }
    },
    phone() {
      if (!this.c) return
      let v = this.c.phone
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `tel:${v}`,
        label: v,
      }
    },
    email() {
      if (!this.c) return
      let v = this.c.email
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `mailto:${v}`,
        label: v,
      }
    },
    facebook() {
      if (!this.c) return
      let v = this.c.facebook
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://facebook.com/${v}`,
        label: v,
      }
    },
    instagram() {
      if (!this.c) return
      let v = this.c.instagram
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://instagram.com/${v}`,
        label: v,
      }
    },
    linkedin() {
      if (!this.c) return
      let v = this.c.linkedin
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://linkedin.com/in/${v}`,
        label: v,
      }
    },
    twitter() {
      if (!this.c) return
      let v = this.c.twitter
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://twitter.com/${v}`,
        label: v,
      }
    },
    github() {
      if (!this.c) return
      let v = this.c.github
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://github.com/${v}`,
        label: v,
      }
    },
    dribbble() {
      if (!this.c) return
      let v = this.c.dribbble
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://dribbble.com/${v}`,
        label: v,
      }
    },
    tripadvisor() {
      if (!this.c) return
      let v = this.c.tripadvisor
      if (!v || !v[this.siteLangCurrent]) return false
      v = v[this.siteLangCurrent]?.trim()
      if (!v) return false
      return {
        href: `https://tripadvisor.com/${v}`,
        label: v,
      }
    },
  },
}
</script>
