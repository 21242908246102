export default [
  {
    name: "Africa/Abidjan",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Accra",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Addis_Ababa",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Algiers",
    abbr: "CET",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Asmara",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Asmera",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Bamako",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Bangui",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Banjul",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Bissau",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Blantyre",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Brazzaville",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Bujumbura",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Cairo",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Casablanca",
    abbr: "+01",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Ceuta",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Conakry",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Dakar",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Dar_es_Salaam",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Djibouti",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Douala",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/El_Aaiun",
    abbr: "+01",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Freetown",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Gaborone",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Harare",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Johannesburg",
    abbr: "SAST",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Juba",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Kampala",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Khartoum",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Kigali",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Kinshasa",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Lagos",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Libreville",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Lome",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Luanda",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Lubumbashi",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Lusaka",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Malabo",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Maputo",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Maseru",
    abbr: "SAST",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Mbabane",
    abbr: "SAST",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Mogadishu",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Monrovia",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Nairobi",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Africa/Ndjamena",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Niamey",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Nouakchott",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Ouagadougou",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Porto-Novo",
    abbr: "WAT",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Sao_Tome",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Timbuktu",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Africa/Tripoli",
    abbr: "EET",
    utc_offset: "02:00:00",
  },
  {
    name: "Africa/Tunis",
    abbr: "CET",
    utc_offset: "01:00:00",
  },
  {
    name: "Africa/Windhoek",
    abbr: "CAT",
    utc_offset: "02:00:00",
  },
  {
    name: "America/Adak",
    abbr: "HDT",
    utc_offset: "-09:00:00",
  },
  {
    name: "America/Anchorage",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/Anguilla",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Antigua",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Araguaina",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Buenos_Aires",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Catamarca",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/ComodRivadavia",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Cordoba",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Jujuy",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/La_Rioja",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Mendoza",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Rio_Gallegos",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Salta",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/San_Juan",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/San_Luis",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Tucuman",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Argentina/Ushuaia",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Aruba",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Asuncion",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Atikokan",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Atka",
    abbr: "HDT",
    utc_offset: "-09:00:00",
  },
  {
    name: "America/Bahia",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Bahia_Banderas",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Barbados",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Belem",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Belize",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Blanc-Sablon",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Boa_Vista",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Bogota",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Boise",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Buenos_Aires",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Cambridge_Bay",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Campo_Grande",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Cancun",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Caracas",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Catamarca",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Cayenne",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Cayman",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Chicago",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Chihuahua",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Ciudad_Juarez",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Coral_Harbour",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Cordoba",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Costa_Rica",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Creston",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Cuiaba",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Curacao",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Danmarkshavn",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "America/Dawson",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Dawson_Creek",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Denver",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Detroit",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Dominica",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Edmonton",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Eirunepe",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/El_Salvador",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Ensenada",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Fort_Nelson",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Fort_Wayne",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Fortaleza",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Glace_Bay",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Godthab",
    abbr: "-01",
    utc_offset: "-01:00:00",
  },
  {
    name: "America/Goose_Bay",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Grand_Turk",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Grenada",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Guadeloupe",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Guatemala",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Guayaquil",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Guyana",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Halifax",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Havana",
    abbr: "CDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Hermosillo",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Indiana/Indianapolis",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indiana/Knox",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Indiana/Marengo",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indiana/Petersburg",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indiana/Tell_City",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Indiana/Vevay",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indiana/Vincennes",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indiana/Winamac",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Indianapolis",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Inuvik",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Iqaluit",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Jamaica",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Jujuy",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Juneau",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/Kentucky/Louisville",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Kentucky/Monticello",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Knox_IN",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Kralendijk",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/La_Paz",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Lima",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Los_Angeles",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Louisville",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Lower_Princes",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Maceio",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Managua",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Manaus",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Marigot",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Martinique",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Matamoros",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Mazatlan",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Mendoza",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Menominee",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Merida",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Metlakatla",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/Mexico_City",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Miquelon",
    abbr: "-02",
    utc_offset: "-02:00:00",
  },
  {
    name: "America/Moncton",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Monterrey",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Montevideo",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Montreal",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Montserrat",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Nassau",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/New_York",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Nipigon",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Nome",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/Noronha",
    abbr: "-02",
    utc_offset: "-02:00:00",
  },
  {
    name: "America/North_Dakota/Beulah",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/North_Dakota/Center",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/North_Dakota/New_Salem",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Nuuk",
    abbr: "-01",
    utc_offset: "-01:00:00",
  },
  {
    name: "America/Ojinaga",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Panama",
    abbr: "EST",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Pangnirtung",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Paramaribo",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Phoenix",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Port-au-Prince",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Port_of_Spain",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Porto_Acre",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Porto_Velho",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Puerto_Rico",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Punta_Arenas",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Rainy_River",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Rankin_Inlet",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Recife",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Regina",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Resolute",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Rio_Branco",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Rosario",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Santa_Isabel",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Santarem",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Santiago",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Santo_Domingo",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Sao_Paulo",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Scoresbysund",
    abbr: "-01",
    utc_offset: "-01:00:00",
  },
  {
    name: "America/Shiprock",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Sitka",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/St_Barthelemy",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/St_Johns",
    abbr: "NDT",
    utc_offset: "-02:30:00",
  },
  {
    name: "America/St_Kitts",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/St_Lucia",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/St_Thomas",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/St_Vincent",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Swift_Current",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Tegucigalpa",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "America/Thule",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "America/Thunder_Bay",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Tijuana",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Toronto",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Tortola",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Vancouver",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Virgin",
    abbr: "AST",
    utc_offset: "-04:00:00",
  },
  {
    name: "America/Whitehorse",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "America/Winnipeg",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "America/Yakutat",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "America/Yellowknife",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "Antarctica/Casey",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Antarctica/Davis",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Antarctica/DumontDUrville",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Antarctica/Macquarie",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Antarctica/Mawson",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Antarctica/McMurdo",
    abbr: "NZST",
    utc_offset: "12:00:00",
  },
  {
    name: "Antarctica/Palmer",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "Antarctica/Rothera",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "Antarctica/South_Pole",
    abbr: "NZST",
    utc_offset: "12:00:00",
  },
  {
    name: "Antarctica/Syowa",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Antarctica/Troll",
    abbr: "+02",
    utc_offset: "02:00:00",
  },
  {
    name: "Antarctica/Vostok",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Arctic/Longyearbyen",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Asia/Aden",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Almaty",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Amman",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Anadyr",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Asia/Aqtau",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Aqtobe",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Ashgabat",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Ashkhabad",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Atyrau",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Baghdad",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Bahrain",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Baku",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Asia/Bangkok",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Barnaul",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Beirut",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Bishkek",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Brunei",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Calcutta",
    abbr: "IST",
    utc_offset: "05:30:00",
  },
  {
    name: "Asia/Chita",
    abbr: "+09",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Choibalsan",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Chongqing",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Chungking",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Colombo",
    abbr: "+0530",
    utc_offset: "05:30:00",
  },
  {
    name: "Asia/Dacca",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Damascus",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Dhaka",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Dili",
    abbr: "+09",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Dubai",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Asia/Dushanbe",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Famagusta",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Gaza",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Harbin",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Hebron",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Ho_Chi_Minh",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Hong_Kong",
    abbr: "HKT",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Hovd",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Irkutsk",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Istanbul",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Jakarta",
    abbr: "WIB",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Jayapura",
    abbr: "WIT",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Jerusalem",
    abbr: "IDT",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Kabul",
    abbr: "+0430",
    utc_offset: "04:30:00",
  },
  {
    name: "Asia/Kamchatka",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Asia/Karachi",
    abbr: "PKT",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Kashgar",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Kathmandu",
    abbr: "+0545",
    utc_offset: "05:45:00",
  },
  {
    name: "Asia/Katmandu",
    abbr: "+0545",
    utc_offset: "05:45:00",
  },
  {
    name: "Asia/Khandyga",
    abbr: "+09",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Kolkata",
    abbr: "IST",
    utc_offset: "05:30:00",
  },
  {
    name: "Asia/Krasnoyarsk",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Kuala_Lumpur",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Kuching",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Kuwait",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Macao",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Macau",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Magadan",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Asia/Makassar",
    abbr: "WITA",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Manila",
    abbr: "PST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Muscat",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Asia/Nicosia",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Novokuznetsk",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Novosibirsk",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Omsk",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Oral",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Phnom_Penh",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Pontianak",
    abbr: "WIB",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Pyongyang",
    abbr: "KST",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Qatar",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Qostanay",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Qyzylorda",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Rangoon",
    abbr: "+0630",
    utc_offset: "06:30:00",
  },
  {
    name: "Asia/Riyadh",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Saigon",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Sakhalin",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Asia/Samarkand",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Seoul",
    abbr: "KST",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Shanghai",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Singapore",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Srednekolymsk",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Asia/Taipei",
    abbr: "CST",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Tashkent",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Tbilisi",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Asia/Tehran",
    abbr: "+0330",
    utc_offset: "03:30:00",
  },
  {
    name: "Asia/Tel_Aviv",
    abbr: "IDT",
    utc_offset: "03:00:00",
  },
  {
    name: "Asia/Thimbu",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Thimphu",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Tokyo",
    abbr: "JST",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Tomsk",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Ujung_Pandang",
    abbr: "WITA",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Ulaanbaatar",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Ulan_Bator",
    abbr: "+08",
    utc_offset: "08:00:00",
  },
  {
    name: "Asia/Urumqi",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Asia/Ust-Nera",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Asia/Vientiane",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Asia/Vladivostok",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Asia/Yakutsk",
    abbr: "+09",
    utc_offset: "09:00:00",
  },
  {
    name: "Asia/Yangon",
    abbr: "+0630",
    utc_offset: "06:30:00",
  },
  {
    name: "Asia/Yekaterinburg",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Asia/Yerevan",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Atlantic/Azores",
    abbr: "+00",
    utc_offset: "00:00:00",
  },
  {
    name: "Atlantic/Bermuda",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "Atlantic/Canary",
    abbr: "WEST",
    utc_offset: "01:00:00",
  },
  {
    name: "Atlantic/Cape_Verde",
    abbr: "-01",
    utc_offset: "-01:00:00",
  },
  {
    name: "Atlantic/Faeroe",
    abbr: "WEST",
    utc_offset: "01:00:00",
  },
  {
    name: "Atlantic/Faroe",
    abbr: "WEST",
    utc_offset: "01:00:00",
  },
  {
    name: "Atlantic/Jan_Mayen",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Atlantic/Madeira",
    abbr: "WEST",
    utc_offset: "01:00:00",
  },
  {
    name: "Atlantic/Reykjavik",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Atlantic/South_Georgia",
    abbr: "-02",
    utc_offset: "-02:00:00",
  },
  {
    name: "Atlantic/St_Helena",
    abbr: "GMT",
    utc_offset: "00:00:00",
  },
  {
    name: "Atlantic/Stanley",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "Australia/ACT",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Adelaide",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Australia/Brisbane",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Broken_Hill",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Australia/Canberra",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Currie",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Darwin",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Australia/Eucla",
    abbr: "+0845",
    utc_offset: "08:45:00",
  },
  {
    name: "Australia/Hobart",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/LHI",
    abbr: "+1030",
    utc_offset: "10:30:00",
  },
  {
    name: "Australia/Lindeman",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Lord_Howe",
    abbr: "+1030",
    utc_offset: "10:30:00",
  },
  {
    name: "Australia/Melbourne",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/NSW",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/North",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Australia/Perth",
    abbr: "AWST",
    utc_offset: "08:00:00",
  },
  {
    name: "Australia/Queensland",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/South",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Australia/Sydney",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Tasmania",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/Victoria",
    abbr: "AEST",
    utc_offset: "10:00:00",
  },
  {
    name: "Australia/West",
    abbr: "AWST",
    utc_offset: "08:00:00",
  },
  {
    name: "Australia/Yancowinna",
    abbr: "ACST",
    utc_offset: "09:30:00",
  },
  {
    name: "Brazil/Acre",
    abbr: "-05",
    utc_offset: "-05:00:00",
  },
  {
    name: "Brazil/DeNoronha",
    abbr: "-02",
    utc_offset: "-02:00:00",
  },
  {
    name: "Brazil/East",
    abbr: "-03",
    utc_offset: "-03:00:00",
  },
  {
    name: "Brazil/West",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "Canada/Atlantic",
    abbr: "ADT",
    utc_offset: "-03:00:00",
  },
  {
    name: "Canada/Central",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "Canada/Eastern",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "Canada/Mountain",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "Canada/Newfoundland",
    abbr: "NDT",
    utc_offset: "-02:30:00",
  },
  {
    name: "Canada/Pacific",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "Canada/Saskatchewan",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "Canada/Yukon",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "Chile/Continental",
    abbr: "-04",
    utc_offset: "-04:00:00",
  },
  {
    name: "Chile/EasterIsland",
    abbr: "-06",
    utc_offset: "-06:00:00",
  },
  {
    name: "Europe/Amsterdam",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Andorra",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Astrakhan",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Europe/Athens",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Belfast",
    abbr: "BST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Belgrade",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Berlin",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Bratislava",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Brussels",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Bucharest",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Budapest",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Busingen",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Chisinau",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Copenhagen",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Dublin",
    abbr: "IST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Gibraltar",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Guernsey",
    abbr: "BST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Helsinki",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Isle_of_Man",
    abbr: "BST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Istanbul",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Jersey",
    abbr: "BST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Kaliningrad",
    abbr: "EET",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Kiev",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Kirov",
    abbr: "MSK",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Kyiv",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Lisbon",
    abbr: "WEST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Ljubljana",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/London",
    abbr: "BST",
    utc_offset: "01:00:00",
  },
  {
    name: "Europe/Luxembourg",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Madrid",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Malta",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Mariehamn",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Minsk",
    abbr: "+03",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Monaco",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Moscow",
    abbr: "MSK",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Nicosia",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Oslo",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Paris",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Podgorica",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Prague",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Riga",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Rome",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Samara",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Europe/San_Marino",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Sarajevo",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Saratov",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Europe/Simferopol",
    abbr: "MSK",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Skopje",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Sofia",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Stockholm",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Tallinn",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Tirane",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Tiraspol",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Ulyanovsk",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Europe/Uzhgorod",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Vaduz",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Vatican",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Vienna",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Vilnius",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Volgograd",
    abbr: "MSK",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Warsaw",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Zagreb",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Europe/Zaporozhye",
    abbr: "EEST",
    utc_offset: "03:00:00",
  },
  {
    name: "Europe/Zurich",
    abbr: "CEST",
    utc_offset: "02:00:00",
  },
  {
    name: "Indian/Antananarivo",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Indian/Chagos",
    abbr: "+06",
    utc_offset: "06:00:00",
  },
  {
    name: "Indian/Christmas",
    abbr: "+07",
    utc_offset: "07:00:00",
  },
  {
    name: "Indian/Cocos",
    abbr: "+0630",
    utc_offset: "06:30:00",
  },
  {
    name: "Indian/Comoro",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Indian/Kerguelen",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Indian/Mahe",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Indian/Maldives",
    abbr: "+05",
    utc_offset: "05:00:00",
  },
  {
    name: "Indian/Mauritius",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Indian/Mayotte",
    abbr: "EAT",
    utc_offset: "03:00:00",
  },
  {
    name: "Indian/Reunion",
    abbr: "+04",
    utc_offset: "04:00:00",
  },
  {
    name: "Mexico/BajaNorte",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "Mexico/BajaSur",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "Mexico/General",
    abbr: "CST",
    utc_offset: "-06:00:00",
  },
  {
    name: "Pacific/Apia",
    abbr: "+13",
    utc_offset: "13:00:00",
  },
  {
    name: "Pacific/Auckland",
    abbr: "NZST",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Bougainville",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Chatham",
    abbr: "+1245",
    utc_offset: "12:45:00",
  },
  {
    name: "Pacific/Chuuk",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Pacific/Easter",
    abbr: "-06",
    utc_offset: "-06:00:00",
  },
  {
    name: "Pacific/Efate",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Enderbury",
    abbr: "+13",
    utc_offset: "13:00:00",
  },
  {
    name: "Pacific/Fakaofo",
    abbr: "+13",
    utc_offset: "13:00:00",
  },
  {
    name: "Pacific/Fiji",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Funafuti",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Galapagos",
    abbr: "-06",
    utc_offset: "-06:00:00",
  },
  {
    name: "Pacific/Gambier",
    abbr: "-09",
    utc_offset: "-09:00:00",
  },
  {
    name: "Pacific/Guadalcanal",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Guam",
    abbr: "ChST",
    utc_offset: "10:00:00",
  },
  {
    name: "Pacific/Honolulu",
    abbr: "HST",
    utc_offset: "-10:00:00",
  },
  {
    name: "Pacific/Johnston",
    abbr: "HST",
    utc_offset: "-10:00:00",
  },
  {
    name: "Pacific/Kanton",
    abbr: "+13",
    utc_offset: "13:00:00",
  },
  {
    name: "Pacific/Kiritimati",
    abbr: "+14",
    utc_offset: "14:00:00",
  },
  {
    name: "Pacific/Kosrae",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Kwajalein",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Majuro",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Marquesas",
    abbr: "-0930",
    utc_offset: "-09:30:00",
  },
  {
    name: "Pacific/Midway",
    abbr: "SST",
    utc_offset: "-11:00:00",
  },
  {
    name: "Pacific/Nauru",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Niue",
    abbr: "-11",
    utc_offset: "-11:00:00",
  },
  {
    name: "Pacific/Norfolk",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Noumea",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Pago_Pago",
    abbr: "SST",
    utc_offset: "-11:00:00",
  },
  {
    name: "Pacific/Palau",
    abbr: "+09",
    utc_offset: "09:00:00",
  },
  {
    name: "Pacific/Pitcairn",
    abbr: "-08",
    utc_offset: "-08:00:00",
  },
  {
    name: "Pacific/Pohnpei",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Ponape",
    abbr: "+11",
    utc_offset: "11:00:00",
  },
  {
    name: "Pacific/Port_Moresby",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Pacific/Rarotonga",
    abbr: "-10",
    utc_offset: "-10:00:00",
  },
  {
    name: "Pacific/Saipan",
    abbr: "ChST",
    utc_offset: "10:00:00",
  },
  {
    name: "Pacific/Samoa",
    abbr: "SST",
    utc_offset: "-11:00:00",
  },
  {
    name: "Pacific/Tahiti",
    abbr: "-10",
    utc_offset: "-10:00:00",
  },
  {
    name: "Pacific/Tarawa",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Tongatapu",
    abbr: "+13",
    utc_offset: "13:00:00",
  },
  {
    name: "Pacific/Truk",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "Pacific/Wake",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Wallis",
    abbr: "+12",
    utc_offset: "12:00:00",
  },
  {
    name: "Pacific/Yap",
    abbr: "+10",
    utc_offset: "10:00:00",
  },
  {
    name: "US/Alaska",
    abbr: "AKDT",
    utc_offset: "-08:00:00",
  },
  {
    name: "US/Aleutian",
    abbr: "HDT",
    utc_offset: "-09:00:00",
  },
  {
    name: "US/Arizona",
    abbr: "MST",
    utc_offset: "-07:00:00",
  },
  {
    name: "US/Central",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "US/East-Indiana",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "US/Eastern",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "US/Hawaii",
    abbr: "HST",
    utc_offset: "-10:00:00",
  },
  {
    name: "US/Indiana-Starke",
    abbr: "CDT",
    utc_offset: "-05:00:00",
  },
  {
    name: "US/Michigan",
    abbr: "EDT",
    utc_offset: "-04:00:00",
  },
  {
    name: "US/Mountain",
    abbr: "MDT",
    utc_offset: "-06:00:00",
  },
  {
    name: "US/Pacific",
    abbr: "PDT",
    utc_offset: "-07:00:00",
  },
  {
    name: "US/Samoa",
    abbr: "SST",
    utc_offset: "-11:00:00",
  },
]
