<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- logo container -->
      <div v-bind="props.logoWrap">
        <!-- logo -->
        <!--<OneImage
        class="max-h-[4rem] children:max-h-[4rem] max-w-[8rem] children:max-w-[8rem]"
          v-bind="props.logo"
          :xstylesConfig="{width: logo.width}"
          :src="logo.src"
          fit="cover"
          :imageId="logo.imageId"
          v-if="logo && logo.src"
        />-->
        <img class="max-w-[8rem] max-h-[2rem]" :src="logo.src" />

        <!-- caption -->
        <!-- <p
      v-bind="props.caption"
      v-if="caption"
      >{{caption}}</p> -->
        <OneParagraph :content="caption" v-bind="props.caption" v-if="caption"></OneParagraph>
      </div>

      <!-- icons -->
      <div class="icons" v-bind="props.icons">
        <!-- website -->
        <OneLink
          v-bind="props.icon"
          :href="website.href"
          @click="onClick('website')"
          v-if="website"
        >
          <OneIcon icon="ContactWebsite" size="small" key="website" />
        </OneLink>

        <!-- phone -->
        <OneLink v-bind="props.icon" :href="phone.href" @click="onClick('phone')" v-if="phone">
          <OneIcon icon="ContactPhone" size="small" key="phone" />
        </OneLink>

        <!-- email -->
        <OneLink v-bind="props.icon" :href="email.href" @click="onClick('email')" v-if="email">
          <OneIcon icon="ContactEmail2" size="small" key="email" />
        </OneLink>

        <!-- facebook -->
        <OneLink
          v-bind="props.icon"
          :href="facebook.href"
          @click="onClick('facebook')"
          v-if="facebook"
        >
          <OneIcon icon="SocialFacebook" size="small" key="facebook" />
        </OneLink>

        <!-- instagram -->
        <OneLink
          v-bind="props.icon"
          :href="instagram.href"
          @click="onClick('instagram')"
          v-if="instagram"
        >
          <OneIcon icon="SocialInstagram" size="small" key="instagram" />
        </OneLink>

        <!-- linkedin -->
        <OneLink
          v-bind="props.icon"
          :href="linkedin.href"
          @click="onClick('linkedin')"
          v-if="linkedin"
        >
          <OneIcon icon="SocialLinkedin" size="small" key="linkedin" />
        </OneLink>

        <!-- twitter -->
        <OneLink
          v-bind="props.icon"
          :href="twitter.href"
          @click="onClick('twitter')"
          v-if="twitter"
        >
          <OneIcon icon="SocialTwitter" size="small" key="twitter" />
        </OneLink>

        <!-- github -->
        <OneLink v-bind="props.icon" :href="github.href" @click="onClick('github')" v-if="github">
          <OneIcon icon="SocialGithub" size="small" key="github" />
        </OneLink>

        <!-- dribbble -->
        <OneLink
          v-bind="props.icon"
          :href="dribbble.href"
          @click="onClick('dribbble')"
          v-if="dribbble"
        >
          <OneIcon icon="SocialDribbble" size="small" key="dribbble" />
        </OneLink>

        <!-- tripadvisor -->
        <OneLink
          v-bind="props.icon"
          :href="tripadvisor.href"
          @click="onClick('tripadvisor')"
          v-if="tripadvisor"
        >
          <OneIcon icon="SocialTripadvisor" size="small" key="tripadvisor" />
        </OneLink>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneFooter1",
  emits: ["clickLink"],
  mixins: [mixinClasses],
  props: {
    logo: {
      type: Object,
    },
    caption: {
      type: [String, Boolean],
    },
    website: {
      type: [Object, Boolean],
      default: null,
    },
    phone: {
      type: [Object, Boolean],
      default: null,
    },
    email: {
      type: [Object, Boolean],
      default: null,
    },
    facebook: {
      type: [Object, Boolean],
      default: null,
    },
    instagram: {
      type: [Object, Boolean],
      default: null,
    },
    linkedin: {
      type: [Object, Boolean],
      default: null,
    },
    twitter: {
      type: [Object, Boolean],
      default: null,
    },
    github: {
      type: [Object, Boolean],
      default: null,
    },
    dribbble: {
      type: [Object, Boolean],
      default: null,
    },
    tripadvisor: {
      type: [Object, Boolean],
      default: null,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full py-10 font-body",
      },
      logoWrap: {
        class: "flex flex-col flex-center",
      },
      caption: {
        class: "py-5 text-center",
      },
      container: {
        class: "flex flex-col",
      },
      icons: {
        class: "flex flex-row pt-5 flex-wrap",
      },
      icon: {
        class: "px-2 pt-2 !w-auto",
      },
    },
  }),
  methods: {
    onClick(kind) {
      this.$emit("clickLink", kind)
    },
  },
}
</script>
