<template>
  <div class="one-list-item px-2 py-2 hover:bg-neutral-150 dark:hover:bg-neutral-950">
    <slot />
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneListItem",
  mixins: [mixinClasses],
  props: {
    contentLayout: {
      type: String,
      default: "flex", // flex | grid
    },
    modelValue: {
      type: Array,
    },

    // all available options for selects
    globalOptions: {
      type: Array,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
    },
  }),
  methods: {
    onInput(v) {
      this.$emit("update:modelValue", v)
      // console.log('onInput', v)
    },
  },
}
</script>

<style lang="less">
@import "../../variables.less";

// .one-layout-list-item {
//   padding: 14px;
//   margin: 8px 0;
//   background: #ffffff;
//   border-radius: 8px;
//   font-size: 1rem;
//   letter-spacing: 1px;
//   box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.03);
//   transition: all 0.25s ease-in-out;

//   &:hover {
//     box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
//   }
// }

.one-list-item {
  a {
    @apply !text-neutral-700;
  }

  &:hover {
    a {
      @apply !underline;
    }
  }
}
</style>
