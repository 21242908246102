import enumsLanguagesTiny from "../enums/languagesTiny.js"

const optionsLanguagesTiny = enumsLanguagesTiny.map((language) => {
  return {
    value: language.iso2,
    label: language.en,
  }
})

export default optionsLanguagesTiny
