// database of currencies, their prefixes, suffixes and formatting

export default {
  en: {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  'en-US': {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: ",",
      },
    },
  },
  'en-GB': {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  cs: {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  'cs-CZ': {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  'sk': {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  'sk-SK': {
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  }
}
