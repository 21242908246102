import enumsCountries from "../enums/countriesTiny.js"

const optionsCountries = enumsCountries.map((country) => {
  return {
    value: country.iso3,
    label: country.cldr,
  }
})

export default optionsCountries
