<template>
  <OneMenu
    :override="{
      logo: {
        src: logoUrl,
        imageId: logoImageId,
      },
    }"
    :variant="variant"
    :title="title"
    :openedSize="openedSize"
    :menuItems="menuItems"
    :siteLangs="siteLangsAvailable"
    :currentLang="siteLangCurrent"
    :fixed="fixed"
    :hamburgerWrapPosition="hamburgerWrapPosition"
    :backButtonPosition="backButtonPosition"
    :logoPosition="logoPosition"
    :cartButtonIcon="cartButtonIcon"
    :cartButtonPosition="cartButtonPosition"
    :cartCurrency="cartCurrency"
    :shopCurrenciesEnabledCodes="shopCurrenciesEnabledCodes"
    :showHamburgerButton="showHamburgerButton"
    :showBackButton="showBackButton"
    :showCartButton="showCartButton"
    :showLangSwitch="showLangSwitch"
    :showUserButton="showUserButton"
    :cartItemsCount="cartItemsCount"
    :autohide="autohide"
    :background="background"
    :backgroundOnScroll="backgroundOnScroll"
    :platform="platform"
    @logo-click="onLogoClick"
    @back-btn-click="onBackBtnClick"
    @cart-btn-click="onCartBtnClick"
    @user-btn-click="onUserBtnClick"
    @set-cart-currency="setCartCurrency"
  />
</template>

<script>
import mixinBlock from "@/mixins/block"
import {useAppStore} from "@/stores/app"
import {useShopStore} from "@/stores/shop"
import {useShopCartStore} from "@/stores/shopCart"
import {useSiteStore} from "@/stores/site"
import {useUserStore} from "@/stores/user"
import {storeToRefs} from "pinia"

export default {
  name: "pfmMenu",
  mixins: [mixinBlock],
  props: ["block"],
  setup() {
    // stores
    const appStore = useAppStore()
    const shopStore = useShopStore()
    const shopCartStore = useShopCartStore()
    const siteStore = useSiteStore()
    const userStore = useUserStore()

    // states
    const {app} = storeToRefs(appStore)
    const {shop} = storeToRefs(shopStore)
    const {cart: shopCart, frontend: shopCartFrontend} = storeToRefs(shopCartStore)
    const {id: siteId, siteVar, siteVarFetched, routes: siteRoutes} = storeToRefs(siteStore)
    const {user} = storeToRefs(userStore)

    // getters
    const {currenciesEnabledCodes: shopCurrenciesEnabledCodes} = storeToRefs(shopStore)
    const {siteLangCurrent, siteLangsAvailable, pages: sitePages} = storeToRefs(siteStore)

    // actions
    const {setCurrency: shopCartSetCurrency, setOpen: shopCartSetOpen} = shopCartStore

    return {
      app,
      siteId,
      siteVar,
      siteVarFetched,
      siteRoutes,
      siteLangCurrent,
      siteLangsAvailable,
      sitePages,
      shop,
      shopCart,
      shopCartSetOpen,
      shopCartSetCurrency,
      shopCartFrontend,
      shopCurrenciesEnabledCodes,
      user,
    }
  },
  data: () => ({
    defaults: {
      elements: {
        logo: {
          styles: {
            width: {
              mobile: {
                value: 75,
                unit: "vw",
              },
              tablet: {
                value: 20,
                unit: "vw",
              },
              desktop: {
                value: 25,
                unit: "vw",
              },
            },
          },
        },
      },
    },
  }),
  computed: {
    variant() {
      return this.block.config.variant
    },
    openedSize() {
      return this.block.config.openedSize
    },
    hamburgerWrapPosition() {
      return this.block.config.hamburgerWrapPosition
    },
    backButtonPosition() {
      return this.block.config.backButtonPosition
    },
    logoPosition() {
      return this.block.config.logoPosition
    },
    cartButtonIcon() {
      return this.block.config.cartButtonIcon
    },
    cartButtonPosition() {
      return this.block.config.cartButtonPosition
    },
    showHamburgerButton() {
      return this.block.config.showHamburgerButton
    },
    showBackButton() {
      return this.$route.meta?.slug != "/" && this.block.config.showBackButton
    },
    showCartButton() {
      return this.hasCommerce ? true : false
    },
    showLangSwitch() {
      return this.block.config.showLangSwitch
    },
    showUserButton() {
      return !!this.hasCommerce
    },
    defaultCurrency() {
      if (!this.hasCommerce || !this.shop || !this.shop.config) return
      return this.shop.config.defaultCurrency
    },
    cartCurrency() {
      if (!this.hasCommerce) return
      if (!this.shopCartFrontend) return false
      let cc = this.shopCartFrontend.currency
      return cc ? cc : this.defaultCurrency
    },
    cartItemsCount() {
      if (!this.showCartButton || !this.shopCart.items) return
      return this.shopCart.items.length
    },
    autohide() {
      return this.block.config.autohide
    },
    background() {
      return this.block.config.background
    },
    backgroundOnScroll() {
      return this.block.config.backgroundOnScroll
    },
    fixed() {
      if (this.app.mode == "admin") return false // so it's selectable
      else return this.block.config.fixed
    },
    menuItems() {
      let mi = []
      if (!this.c || !this.c.menuItems) return mi
      this.c.menuItems.forEach((i) => {
        let kind = i.link && i.link.kind ? i.link.kind : i.kind

        let item = {
          title: i.title ? i.title[this.siteLangCurrent] : "",
          kind,
        }
        if (kind == "page") {
          item.path = this.getPagePath(i.link.pageId, this.siteLangCurrent)
          item.pageId = i.link.pageId
        }
        if (kind == "url") {
          item.href = i.link
            ? i.link.url && typeof i.link.url === "string"
              ? i.link.url
              : i.link.url[this.siteLangCurrent]
            : ""
        }
        mi.push(item)
      })
      return mi
    },
    hasCommerce() {
      return this.siteVarFetched && this.siteVar.shopId
    },
    platform() {
      return this.siteVar?.global?.platform
    },
    logoImage() {
      if (!this.c || !this.c.logo || !this.c.logo[this.siteLangCurrent]) return false
      return this.c.logo[this.siteLangCurrent]
    },
    logoImageId() {
      return this.logoImage.imageId
    },
    logoUrl() {
      let image = this.logoImage

      let params = "$w_320,q_80,x_3" // default is jpeg
      if (image.contentType == "image/svg+xml") params = ""
      if (image.contentType == "image/jpeg") params = "$w_320,q_80,x_3"
      if (image.contentType == "image/png") params = "$w_320,c_9,x_3"

      let logoUrl = `${import.meta.env.VITE_URL_STATIC}/sites/${this.siteId}/images/${
        this.logoImageId
      }${params}`
      // console.log('logoUrl:', logoUrl)
      return this.logoImageId ? logoUrl : ""
    },
    backgroundUrl() {
      if (!this.c || !this.c.background || !this.c.background[this.siteLangCurrent]) return false
      let image = this.c.background[this.siteLangCurrent]
      let imageId = image.imageId

      let params = "$w_1800,q_80,x_3" // default is jpeg
      if (image.contentType == "image/svg+xml") params = ""
      if (image.contentType == "image/jpeg") params = "$w_1800,q_80,x_3"
      if (image.contentType == "image/png") params = "$w_1800,c_9,x_3"

      let logoUrl = `${import.meta.env.VITE_URL_STATIC}/sites/${
        this.siteId
      }/images/${imageId}${params}`
      // console.log('logoUrl:', logoUrl)
      return logoUrl
    },
    title() {
      if (!this.c.title) return
      let t = this.c.title
      return {
        title: t.title[this.siteLangCurrent],
        link: {
          kind: t.link.kind,
          pageId: t.link.pageId,
          url: t.link.url[this.siteLangCurrent],
        },
      }
    },
  },
  methods: {
    onLogoClick() {
      console.log("Menu.vue: onLogoClick:")
      if (this.$route.path != "/") this.$router.push("/")
    },
    onBackBtnClick() {
      if (this.app.lastProductDetailOpenedFrom) {
        this.$router.push(this.app.lastProductDetailOpenedFrom.path)
      } else {
        this.$router.go(-1)
      }
    },
    onCartBtnClick() {
      this.shopCartSetOpen()
    },
    onUserBtnClick() {
      console.log("clicked user button", this.user)
      // if signed in, go to account
      // if (this.user) {
        this.$router.push({name: "accountView"})
      // }
      // if not signed in, open sign in modal
      // else {
      // }
    },
    async setCartCurrency(currency) {
      await this.shopCartSetCurrency(currency)
    },
    getPagePath(pageId, lang) {
      // console.log("getPagePath", pageId, lang, this.siteRoutes, this.siteRoutesReversed)
      if (!pageId) return ""
      if (!this.siteRoutes || Object.keys(this.siteRoutes).length === 0) return ""
      let page = this.sitePages.find((p) => p.id == pageId)
      // console.log('page', page)
      let pageSlugs = page?.slug
      // console.log('pageSlugs', pageSlugs)
      let pageSlug = pageSlugs ? pageSlugs[lang] : "/"
      let route = Object.entries(this.siteRoutes).find((r) => {
        let key = r[0]
        let val = r[1]
        // console.log('r', r)
        return val == pageId && key.endsWith(pageSlug)
      })

      // console.log('route', route, this.siteRoutes)
      if (route) route = route[0]

      return route
    },
  },
}
</script>

<style scoped lang="less">
span.btn-back-fill {
  display: block;
  width: 48px;
  height: 48px;
}

svg.btn-back,
svg.btn-cart {
  width: 48px;
  height: 48px;

  * {
    fill: var(--color-background-dark);
  }

  &:hover {
    * {
      opacity: 0.75;
    }
  }
}
</style>
