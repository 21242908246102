import enumsCountries from '../enums/countriesTiny.js'

const optionsCountries = enumsCountries.map((country) => {
  return {
    value: country.iso3,
    label: country.cldr
  }
})

const optionsCountriesWithGlobal = [
  {
    value: 'global',
    label: 'Global (all countries covered)'
  },
  ...optionsCountries
]

export default optionsCountriesWithGlobal
