// https://datahub.io/core/language-codes
// ISO 639-1

export default [
  {iso2: "aa", en: "Afar"},
  {iso2: "ab", en: "Abkhazian"},
  {iso2: "ae", en: "Avestan"},
  {iso2: "af", en: "Afrikaans"},
  {iso2: "ak", en: "Akan"},
  {iso2: "am", en: "Amharic"},
  {iso2: "an", en: "Aragonese"},
  {iso2: "ar", en: "Arabic"},
  {iso2: "as", en: "Assamese"},
  {iso2: "av", en: "Avaric"},
  {iso2: "ay", en: "Aymara"},
  {iso2: "az", en: "Azerbaijani"},
  {iso2: "ba", en: "Bashkir"},
  {iso2: "be", en: "Belarusian"},
  {iso2: "bg", en: "Bulgarian"},
  {iso2: "bh", en: "Bihari languages"},
  {iso2: "bi", en: "Bislama"},
  {iso2: "bm", en: "Bambara"},
  {iso2: "bn", en: "Bengali"},
  {iso2: "bo", en: "Tibetan"},
  {iso2: "br", en: "Breton"},
  {iso2: "bs", en: "Bosnian"},
  {iso2: "ca", en: "Catalan; Valencian"},
  {iso2: "ce", en: "Chechen"},
  {iso2: "ch", en: "Chamorro"},
  {iso2: "co", en: "Corsican"},
  {iso2: "cr", en: "Cree"},
  {iso2: "cs", en: "Czech"},
  {
    iso2: "cu",
    en:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  },
  {iso2: "cv", en: "Chuvash"},
  {iso2: "cy", en: "Welsh"},
  {iso2: "da", en: "Danish"},
  {iso2: "de", en: "German"},
  {iso2: "dv", en: "Divehi; Dhivehi; Maldivian"},
  {iso2: "dz", en: "Dzongkha"},
  {iso2: "ee", en: "Ewe"},
  {iso2: "el", en: "Greek, Modern (1453-)"},
  {iso2: "en", en: "English"},
  {iso2: "eo", en: "Esperanto"},
  {iso2: "es", en: "Spanish; Castilian"},
  {iso2: "et", en: "Estonian"},
  {iso2: "eu", en: "Basque"},
  {iso2: "fa", en: "Persian"},
  {iso2: "ff", en: "Fulah"},
  {iso2: "fi", en: "Finnish"},
  {iso2: "fj", en: "Fijian"},
  {iso2: "fo", en: "Faroese"},
  {iso2: "fr", en: "French"},
  {iso2: "fy", en: "Western Frisian"},
  {iso2: "ga", en: "Irish"},
  {iso2: "gd", en: "Gaelic; Scottish Gaelic"},
  {iso2: "gl", en: "Galician"},
  {iso2: "gn", en: "Guarani"},
  {iso2: "gu", en: "Gujarati"},
  {iso2: "gv", en: "Manx"},
  {iso2: "ha", en: "Hausa"},
  {iso2: "he", en: "Hebrew"},
  {iso2: "hi", en: "Hindi"},
  {iso2: "ho", en: "Hiri Motu"},
  {iso2: "hr", en: "Croatian"},
  {iso2: "ht", en: "Haitian; Haitian Creole"},
  {iso2: "hu", en: "Hungarian"},
  {iso2: "hy", en: "Armenian"},
  {iso2: "hz", en: "Herero"},
  {
    iso2: "ia",
    en: "Interlingua (International Auxiliary Language Association)",
  },
  {iso2: "id", en: "Indonesian"},
  {iso2: "ie", en: "Interlingue; Occidental"},
  {iso2: "ig", en: "Igbo"},
  {iso2: "ii", en: "Sichuan Yi; Nuosu"},
  {iso2: "ik", en: "Inupiaq"},
  {iso2: "io", en: "Ido"},
  {iso2: "is", en: "Icelandic"},
  {iso2: "it", en: "Italian"},
  {iso2: "iu", en: "Inuktitut"},
  {iso2: "ja", en: "Japanese"},
  {iso2: "jv", en: "Javanese"},
  {iso2: "ka", en: "Georgian"},
  {iso2: "kg", en: "Kongo"},
  {iso2: "ki", en: "Kikuyu; Gikuyu"},
  {iso2: "kj", en: "Kuanyama; Kwanyama"},
  {iso2: "kk", en: "Kazakh"},
  {iso2: "kl", en: "Kalaallisut; Greenlandic"},
  {iso2: "km", en: "Central Khmer"},
  {iso2: "kn", en: "Kannada"},
  {iso2: "ko", en: "Korean"},
  {iso2: "kr", en: "Kanuri"},
  {iso2: "ks", en: "Kashmiri"},
  {iso2: "ku", en: "Kurdish"},
  {iso2: "kv", en: "Komi"},
  {iso2: "kw", en: "Cornish"},
  {iso2: "ky", en: "Kirghiz; Kyrgyz"},
  {iso2: "la", en: "Latin"},
  {iso2: "lb", en: "Luxembourgish; Letzeburgesch"},
  {iso2: "lg", en: "Ganda"},
  {iso2: "li", en: "Limburgan; Limburger; Limburgish"},
  {iso2: "ln", en: "Lingala"},
  {iso2: "lo", en: "Lao"},
  {iso2: "lt", en: "Lithuanian"},
  {iso2: "lu", en: "Luba-Katanga"},
  {iso2: "lv", en: "Latvian"},
  {iso2: "mg", en: "Malagasy"},
  {iso2: "mh", en: "Marshallese"},
  {iso2: "mi", en: "Maori"},
  {iso2: "mk", en: "Macedonian"},
  {iso2: "ml", en: "Malayalam"},
  {iso2: "mn", en: "Mongolian"},
  {iso2: "mr", en: "Marathi"},
  {iso2: "ms", en: "Malay"},
  {iso2: "mt", en: "Maltese"},
  {iso2: "my", en: "Burmese"},
  {iso2: "na", en: "Nauru"},
  {iso2: "nb", en: "Bokmål, Norwegian; Norwegian Bokmål"},
  {iso2: "nd", en: "Ndebele, North; North Ndebele"},
  {iso2: "ne", en: "Nepali"},
  {iso2: "ng", en: "Ndonga"},
  {iso2: "nl", en: "Dutch; Flemish"},
  {iso2: "nn", en: "Norwegian Nynorsk; Nynorsk, Norwegian"},
  {iso2: "no", en: "Norwegian"},
  {iso2: "nr", en: "Ndebele, South; South Ndebele"},
  {iso2: "nv", en: "Navajo; Navaho"},
  {iso2: "ny", en: "Chichewa; Chewa; Nyanja"},
  {iso2: "oc", en: "Occitan (post 1500); Provençal"},
  {iso2: "oj", en: "Ojibwa"},
  {iso2: "om", en: "Oromo"},
  {iso2: "or", en: "Oriya"},
  {iso2: "os", en: "Ossetian; Ossetic"},
  {iso2: "pa", en: "Panjabi; Punjabi"},
  {iso2: "pi", en: "Pali"},
  {iso2: "pl", en: "Polish"},
  {iso2: "ps", en: "Pushto; Pashto"},
  {iso2: "pt", en: "Portuguese"},
  {iso2: "qu", en: "Quechua"},
  {iso2: "rm", en: "Romansh"},
  {iso2: "rn", en: "Rundi"},
  {iso2: "ro", en: "Romanian; Moldavian; Moldovan"},
  {iso2: "ru", en: "Russian"},
  {iso2: "rw", en: "Kinyarwanda"},
  {iso2: "sa", en: "Sanskrit"},
  {iso2: "sc", en: "Sardinian"},
  {iso2: "sd", en: "Sindhi"},
  {iso2: "se", en: "Northern Sami"},
  {iso2: "sg", en: "Sango"},
  {iso2: "si", en: "Sinhala; Sinhalese"},
  {iso2: "sk", en: "Slovak"},
  {iso2: "sl", en: "Slovenian"},
  {iso2: "sm", en: "Samoan"},
  {iso2: "sn", en: "Shona"},
  {iso2: "so", en: "Somali"},
  {iso2: "sq", en: "Albanian"},
  {iso2: "sr", en: "Serbian"},
  {iso2: "ss", en: "Swati"},
  {iso2: "st", en: "Sotho, Southern"},
  {iso2: "su", en: "Sundanese"},
  {iso2: "sv", en: "Swedish"},
  {iso2: "sw", en: "Swahili"},
  {iso2: "ta", en: "Tamil"},
  {iso2: "te", en: "Telugu"},
  {iso2: "tg", en: "Tajik"},
  {iso2: "th", en: "Thai"},
  {iso2: "ti", en: "Tigrinya"},
  {iso2: "tk", en: "Turkmen"},
  {iso2: "tl", en: "Tagalog"},
  {iso2: "tn", en: "Tswana"},
  {iso2: "to", en: "Tonga (Tonga Islands)"},
  {iso2: "tr", en: "Turkish"},
  {iso2: "ts", en: "Tsonga"},
  {iso2: "tt", en: "Tatar"},
  {iso2: "tw", en: "Twi"},
  {iso2: "ty", en: "Tahitian"},
  {iso2: "ug", en: "Uighur; Uyghur"},
  {iso2: "uk", en: "Ukrainian"},
  {iso2: "ur", en: "Urdu"},
  {iso2: "uz", en: "Uzbek"},
  {iso2: "ve", en: "Venda"},
  {iso2: "vi", en: "Vietnamese"},
  {iso2: "vo", en: "Volapük"},
  {iso2: "wa", en: "Walloon"},
  {iso2: "wo", en: "Wolof"},
  {iso2: "xh", en: "Xhosa"},
  {iso2: "yi", en: "Yiddish"},
  {iso2: "yo", en: "Yoruba"},
  {iso2: "za", en: "Zhuang; Chuang"},
  {iso2: "zh", en: "Chinese"},
  {iso2: "zu", en: "Zulu"},
]
