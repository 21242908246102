import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "60px",
  height: "20px",
  viewBox: "0 0 60 20",
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "Tape_svg__v3",
      stroke: "none",
      "stroke-width": "1",
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", {
        d: "M16.0173427,7.40131579 L16.0173427,19.7368421 L15.0173427,19.7368421 L15.0173427,7.40131579 L16.0173427,7.40131579 Z M59.0927154,7.40131579 L59.0927154,19.7368421 L58.0927154,19.7368421 L58.0927154,7.40131579 L59.0927154,7.40131579 Z M30.8758002,1.8189894e-12 L30.8758002,19.7368421 L28.8758002,19.7368421 L28.8758002,1.8189894e-12 L30.8758002,1.8189894e-12 Z M1.65888509,7.40131579 L1.65888509,19.7368421 L0.658885094,19.7368421 L0.658885094,7.40131579 L1.65888509,7.40131579 Z M44.7342578,7.40131579 L44.7342578,19.7368421 L43.7342578,19.7368421 L43.7342578,7.40131579 L44.7342578,7.40131579 Z",
        id: "Tape_svg__Combined-Shape",
        fill: "#C0C0C0",
        "fill-rule": "nonzero"
      })
    ], -1)
  ])))
}
export default { render: render }